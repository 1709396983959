<template lang="pug">
div.mainBody
    b-alert(class="w-100" style="position: fixed; top: 1%; font-size: 1rem; z-index: 5; margin: 0;" :variant="alert.color" :show="alert.dismissCountDown" fade @dismissed="alert.dismissCountDown=0" @dismiss-count-down="callAlert")
      | {{ alert.alertMessage }}

    ModalConfirmation(:id="modalListId[0]" title="Save Confirmation" message="Are you sure you want to save this personal information?" btn-ok-text="Save" :is-show-spinner="isShowSpinner" @modal-cancel="modalCancelActivity" @modal-confirm="modalConfirmActivity")

    ModalConfirmation(:id="modalListId[1]" title="Populate Personal Info" message="Some client information are already filled, do you wish to continue from there?" btn-ok-text="Yes" @modal-cancel="modalCancelActivity" @modal-confirm="modalConfirmActivity")
    
    header-component
    <!-- b-button.bg-success(v-if="isShowSavePersonalInfoBtn" style="display: block; position: fixed; right: 2%;" @click="openModal(0)")
      | Save Client Draft
    b-button.bg-success(v-else style="display: none; position: fixed; right: 2%;")
      | Save Client Draft -->
            
    div.formBody
      h3.my-4 Client Information
      .margin-container
        .personalInformation   
          .row
            .col-sm.my-2
              b-card.h-100.shadow(header-bg-variant="primary" header-tag="header" header-text-variant="white")
                template(#header)
                  h5.mb-0.py-1 Name & NRIC
                    span.compulsory.mx-2 *
                .d-flex.flex-column
                  div.mb-1
                      label.form-label() Enter Full Name (NRIC)*
                      input#exampleFormControlInput1.form-control(type="text" v-model="client.personalInfo.crb5c_no")
                      label.text-danger(v-if="client.personalInfo.crb5c_no && invalidName") Invalid Name
                  div.my-1
                      label.form-label.noWhiteSpace(for="exampleFormControlInput2") Enter Chinese Name (as NRIC if applicable)
                      input#exampleFormControlInput2.form-control(type="text"  v-model="client.personalInfo.crb5c_chinesename")
                  div.my-1
                      label.form-label(for="exampleFormControlInput3") Identity Info*
                      v-select(:options="idOptions" v-model="client.personalInfo.crb5c_idtype" :reduce="d => idOptions.findIndex(dop => dop === d)")
                  div.my-1
                      label.form-label(for="exampleFormControlInput4") Enter NRIC/FIN No* 
                      input#exampleFormControlInput4.form-control(type="text"  v-model="client.personalInfo.crb5c_nricno")
            .col-sm.my-2
              b-card.h-100.shadow(header-bg-variant="primary" header-tag="header" header-text-variant="white")
                template(#header)
                  h5.mb-0.py-1 Profile Picture
                    span.compulsory.mx-2 *
                label.form-label(for="formFile") Choose a Profile Picture
                b-form-file#no-wrong.form-control(ref="profileImgFile" type="file" v-model="client.personalInfo.profilePic" @change="profilePicSelected" :state="Boolean(client.personalInfo.profilePic)")
                b-img.mt-3(ref="profileImg" id="profileImg" fluid)
          
          .row.mt-3  
              .col-sm.my-2
                b-card.h-100.shadow(header-bg-variant="primary" header-tag="header" header-text-variant="white")
                  template(#header)
                    h5.mb-0.py-1 Contact & Relationship
                      span.compulsory.mx-2 *
                  .d-flex.flex-column 
                    div.mb-1
                      label.form-label() My Contact Number (Mobile)* (+65)
                      input#exampleFormControlInput1.form-control(
                        :class="{'is-invalid': mobileContactInvalid}"
                        type="number"
                        v-model="client.personalInfo.crb5c_mycontactnumbermobile"
                        placeholder="31567899"
                      )
                      label.text-danger(v-if="client.personalInfo.crb5c_mycontactnumbermobile && mobileContactInvalid") Invalid Phone Number
                    div.my-1
                      label.form-label() My Contact Number (Home)* (+65)
                      input#exampleFormControlInput1.form-control(
                        :class="{'is-invalid': homeContactInvalid}"
                        type="number"
                        v-model="client.personalInfo.crb5c_mycontactnumberhome"
                        placeholder="31567899"
                      )
                      label.text-danger(v-if="client.personalInfo.crb5c_mycontactnumberhome && homeContactInvalid") Invalid Phone Number
                    div.my-1
                      label.form-label() Enter Date of Birth*
                      datepicker(input-class="form-control" :format="'dd/MM/yyyy'" v-model="client.personalInfo.dob")
                    div.my-1
                      label.form-label() Gender*
                      v-select(:options="sexOptions" v-model="client.personalInfo.crb5c_sex" :reduce="d => sexOptions.findIndex(dop => dop === d)")
                    div.my-1 
                      label.form-label() Marital Status*
                      v-select(:options="maritalOption" v-model="client.personalInfo.crb5c_maritalstatus" :reduce="d => maritalOption.findIndex(dop => dop === d)")
              //------------------------------------------------
              .col-sm.my-2
                b-card.h-100.shadow( header-bg-variant="primary" header-tag="header" header-text-variant="white")
                  template(#header)
                    h5.mb-0.py-1 Nationality & Race
                      span.compulsory.mx-2 *
                  .d-flex.flex-column
                    div.mb-1 
                      label.form-label() Citizenship* 
                      v-select(:options="citizenShipOption" v-model="client.personalInfo.crb5c_citizenship" :reduce="d => citizenShipOption.findIndex(dop => dop === d)")
                    div.my-1
                      label.form-label() Nationality* 
                      v-select(:options="nationalityOption" v-model="client.personalInfo.nationality")
                    div.my-1(v-show="client.personalInfo.nationality==='Others'")
                      label.form-label() Other Nationality*
                      v-select(:options="countryList" v-model="client.personalInfo.otherNationality")
                    div.my-1
                        label.form-label() Dialect (you may select one or multiple)
                        v-select(:options="crb5c_dialectOption" multiple taggable="" v-model="client.personalInfo.dialect")
                    div.my-1
                        label.form-label() Race (Optional)
                        v-select(:options="raceOption" v-model="client.personalInfo.race")
                    div.my-1(v-show="client.personalInfo.race==='Others'")
                      label.form-label() Race Others 
                      v-select(:options="countryList" taggable="" v-model="client.personalInfo.raceOthers")
                    div.my-1
                        label.form-label() Religion (Optional)
                        v-select(:options="religionList" taggable="" v-model="client.personalInfo.religion")
                    div.my-1(v-show="client.personalInfo.religion==='Other'")
                      label.form-label() Religion Others
                      input#exampleFormControlInput1.form-control(v-model="client.personalInfo.religionOthers")
              //------------------------------------------------
              .col-sm.my-2
                b-card.h-100.shadow(header-bg-variant="primary" header-tag="header" header-text-variant="white")
                  template(#header)
                    h5.mb-0.py-1 Address & Property
                      span.compulsory.mx-2 *
                  .d-flex.flex-column 
                    div.mb-1
                      label.form-label() Postal Code*
                      input#exampleFormControlInput1.form-control(type="number" v-model="client.personalInfo.crb5c_postcode")
                    div.my-1(v-show="client.personalInfo.crb5c_postcode!==''")
                      label.form-label() Address Line 1
                      input#exampleFormControlInput1.form-control(type="text" v-model="client.personalInfo.address")
                    div.my-1(v-show="client.personalInfo.crb5c_postcode!==''")
                      label.form-label() Address Line 2
                      input#exampleFormControlInput1.form-control(type="text" v-model="client.personalInfo.addressLineTwo" placeholder="Apartment No, Floor, Building No.")
                    div.my-1
                      label.form-label() Residing with (you may select one or multiple)*
                      v-select(taggable="" multiple :options="crb5c_residingwithOption" v-model="client.personalInfo.residingWith") 
                    div.my-1
                      label.form-label() House Type*
                      b-form-select.select(:options="houseTypeOption" v-model="client.personalInfo.crb5c_propertytype")
                    div.my-1        
                      label.form-label() House Ownership*
                      b-form-select.select(:options="homeOwnershipOption" v-model="client.personalInfo.crb5c_typeofhome")
                    div.my-1(v-show='client.personalInfo.crb5c_propertytype===13')
                      label.form-label() Please specify the house type
                      input#exampleFormControlInput1.form-control(type="text" v-model="client.personalInfo.crb5c_propertytypeothers")

          
          .row.mt-3
              .col-sm.my-2(v-show="validateTypesOfHome") 
                b-card.h-100.shadow(header-bg-variant="primary" header-tag="header" header-text-variant="white")
                  template(#header)
                    h5.mb-0.py-1 Language & Proficiency
                      span.compulsory.mx-2 *
                  .d-flex.flex-column 
                    div.mb-1
                      label.form-label() Spoken Languages (you may select one or multiple, or type and press enter)*
                      v-select(:options="spokenLangOptions" multiple taggable="" v-model="client.language.spokenLang") 
                    div.my-1
                      label.form-label() Written Languages (you may select one or multiple, or type and press enter)*  
                      v-select(:options="writtenLangOptions" multiple taggable="" v-model="client.language.writtenLang") 
                    div.my-1
                      label.form-label() Spoken Dialects (you may select one or multiple, or type and press enter)
                      v-select(:options="crb5c_dialectOption" multiple taggable="" v-model="client.language.spokenDialect")
              //------------------------------------------------
              .col-sm.my-2(v-show="validateLangProf")
                b-card.h-100.shadow( header-bg-variant="primary" header-tag="header" header-text-variant="white")
                  template(#header)
                    h5.mb-0.py-1 Academics & Skill Attained
                      span.compulsory.mx-2 *
                  .d-flex.flex-column
                    div.mb-1 
                      label.form-label() Highest Qualification Attained* 
                      b-form-select.select(:options="highestEduOption" v-model="client.education.educationHighest")
                    div.my-1
                      label.form-label() Previous Occupation
                      input#exampleFormControlInput1.form-control(type="text" v-model="client.education.previousOccupation")
                    div.my-1
                      label.form-label() Hobbies / Interests (you may select one or multiple, or type and press enter)* 
                      v-select(label="Hobbies" taggable=""  multiple="" :options="hobbiesOption" v-model="client.education.hobbies")
              //------------------------------------------------
              .col-sm.my-2(v-show="validateAcademic")
                b-card.h-100.shadow(header-bg-variant="primary" header-tag="header" header-text-variant="white")
                  template(#header)
                    h5.mb-0.py-1 Other Useful Information
                  .d-flex.flex-column 
                    div.mb-1
                      label.form-label() Other Useful Info
                      textarea#exampleFormControlInput1.form-control(type="text" rows="8" v-model="client.personalInfo.crb5c_otherinfo" maxlength="200")

              
          //-h5.mt-4.mb-4(v-show="validateTypesOfHome") LANGUAGE PROFICIENCY
          //-.languageType(v-show="validateTypesOfHome")
              .row.mt-3
                  .col-sm
                      label.form-label() Spoken Languages (you may select one or multiple, or type and press enter)*
                      v-select(:options="spokenLangOptions" multiple taggable="" v-model="client.language.spokenLang") 
                      //- b-form-select.select(:options="spokenLangOptions" v-model="client.language.spokenLang")


                  //- .col-sm(v-show='client.language.spokenLang==="Others"')
                  //-     label.form-label() if other Spoken language
                  //-     input#exampleFormControlInput1.form-control(type="text" v-model="client.language.ifOtherSpoken")
                  
              .row.mt-3
                  .col-sm
                      label.form-label() Written Languages (you may select one or multiple, or type and press enter)*  
                      v-select(:options="writtenLangOptions" multiple taggable="" v-model="client.language.writtenLang") 
                      //- b-form-select.select(:options="writtenLangOption" v-model="client.language.writtenLang")
                  //- .col-sm(v-show='client.language.writtenLang==="Others"')
                  //-     label.form-label() if other Written Languages
                  //-     input#exampleFormControlInput1.form-control(type="text" v-model="client.language.ifOtherWritten")
            
              .row.mt-3
                  .col-sm
                      label.form-label() Spoken Dialects (you may select one or multiple, or type and press enter)
                      v-select(:options="crb5c_dialectOption" multiple taggable="" v-model="client.language.spokenDialect")
                      //- b-form-select.select(:options="spokenDialectOption" v-model="client.language.spokenDialect")
                  //- //need to fix
                  //- .col-sm(v-show='client.language.spokenDialect==="Others"')
                  //-     label.form-label() if other Spoken Dialects
                  //-     input#exampleFormControlInput1.form-control(type="text" v-model="client.language.ifOtherDialect")
              
              hr
          
          //-h5.mt-4.mb-4(v-show="validateLangProf") ACADEMIC / SKILLS ATTAINED
          //-.languageType(v-show="validateLangProf")
              .row.mt-3
                  .col-sm
                      label.form-label() Highest Qualification Attained* 
                      b-form-select.select(:options="highestEduOption" v-model="client.education.educationHighest")
                  .col-sm
                      label.form-label() Previous Occupation
                      input#exampleFormControlInput1.form-control(type="text" v-model="client.education.previousOccupation")
              .row.mt-3
                  .col-sm
                      
                      label.form-label() Hobbies / Interests (you may select one or multiple, or type and press enter)* 
                      v-select(label="Hobbies" taggable=""  multiple="" :options="hobbiesOption" v-model="client.education.hobbies")
              hr
          
          //-h5.mt-4.mb-4(v-show="validateAcademic") OTHER USEFUL INFORMATION
          //-.otherInfo(v-show="validateAcademic")
              .row.mt-3
                  .col-sm
                      label.form-label() Other Useful Info
                      textarea#exampleFormControlInput1.form-control(type="text" v-model="client.personalInfo.crb5c_otherinfo" maxlength="200")
              hr
        
          .row 
            .col-sm.my-2
              b-card.h-100.shadow(header-bg-variant="primary" header-tag="header" header-text-variant="white" v-show="validateAcademic")
                template(#header)
                  h5.my-2(v-show="validateAcademic") CLIENT’S FUNCTIONAL STATUS (体格功能)
                    span.compulsory.mx-2 *
                  h6.my-2(v-show="validateAcademic") Please let us understand your loved one’s current functional status. <br>  请让我们了解您所爱之人目前的体格功能状态. 
                .funtionStatus.mt-4(v-show="validateAcademic")
                    .row.my-2
                        .col-sm
                            label.form-label() Tasks such as bill paying, cooking, cleaning, travelling become affected* <br/>支付账单、烹饪、清洁、旅行等任务受到影响*
                        .col-sm
                            b-form-select.select(:options="yesNoOptions" v-model="client.personalInfo.crb5c_functionalquestiontasksbilling")
                    
                    .row.my-2
                        .col-sm
                            label.form-label() Does he/she need help when selecting the proper attire? <br>他/她在选择合适的着装时需要帮助吗* 

                        .col-sm
                            b-form-select.select(:options="yesNoOptions" v-model="client.personalInfo.crb5c_functionalquestionproperattire")

                    .row.my-2
                        .col-sm
                            label.form-label() Does he/she need help when wearing clothes? <br>他/她在穿衣服时需要帮助吗?* 

                        .col-sm
                            b-form-select.select(:options="yesNoOptions" v-model="client.personalInfo.crb5c_functionalquestionwearingclothes")

                    .row.my-2
                        .col-sm
                            label.form-label() Does he/she need help in bathing?<br> 他/她洗澡时需要帮助吗?* 

                        .col-sm
                            b-form-select.select(:options="yesNoOptions" v-model="client.personalInfo.crb5c_functionalquestionbathing")

                    .row.my-2
                        .col-sm
                            label.form-label() Does he/she need help in toileting? <br>他/她在上厕所时需要帮助吗* 

                        .col-sm
                            b-form-select.select(:options="yesNoOptions" v-model="client.personalInfo.crb5c_functionalquestiontoileting")

                    .row.my-2
                        .col-sm
                            label.form-label() Does he/she have urinary incontinence?	<br>他/她是否有尿失禁？* 

                        .col-sm
                            b-form-select.select(:options="yesNoOptions" v-model="client.personalInfo.crb5c_functionalquestionurinary")

                    .row.my-2
                        .col-sm
                            label.form-label() Does he/she have fecal incontinence? <br>他/她是否有大便失禁?* 

                        .col-sm
                            b-form-select.select(:options="yesNoOptions" v-model="client.personalInfo.crb5c_functionalquestionfecalincontinence")
                    //this will be hidden
                    .row.my-2(v-show="false")
                        .col-sm
                            h5(style='text-align:start;') Status
                        .col-sm
                            h5(style='text-align:start;' ) {{clientFuncRisk}}

          .row 
            .col-sm.my-2
              b-card.h-100.shadow(header-bg-variant="primary" header-tag="header" header-text-variant="white" v-show="validateClientFunc")
                template(#header)
                  h5.my-2(v-show="validateClientFunc") CLIENT’S WELL-BEING PROFILING (幸福指数)
                    span.compulsory.mx-2 *
                  h6.my-2(v-show="validateClientFunc") Please let us understand the well-being of your loved one who has dementia. 
                  h6.my-2(v-show="validateClientFunc") 请让我们了解您所爱的痴呆症患者的幸福状况.
                p.mb-b(style="text-align:start;"
                  v-show="validateClientFunc")  Well-being indicators (幸福指数) 
                .wellBeing(v-show="validateClientFunc")
                    .row.my-2
                        .col-sm
                            label.form-label( style="text-align: start;") 1.	Can communicate wants, needs and choices <br>(可以沟通欲望，需求和选择)
                        .col-sm
                            b-form-select.select(:options="wellBeingOptions" v-model="client.personalInfo.crb5c_wellbeingquestioncommunicate")
                    
                    .row.my-2
                        .col-sm
                            label.form-label() 2.	Makes contact with other people <br>(能够与其他人接触)* 
                        .col-sm
                            b-form-select.select(:options="wellBeingOptions" v-model="client.personalInfo.crb5c_wellbeingquestionmakecontact")
                    
                    .row.my-2
                        .col-sm
                            label.form-label() 3.	Shows warmth or affection <br>(能够显示温情或亲情)* 
                        .col-sm
                            b-form-select.select(:options="wellBeingOptions" v-model="client.personalInfo.crb5c_wellbeingquestionwarmth")

                    .row.my-2
                        .col-sm
                            label.form-label() 4.	Shows pleasure or enjoyment <br>(能够显示快乐或享受)* 
                        .col-sm
                            b-form-select.select(:options="wellBeingOptions" v-model="client.personalInfo.crb5c_wellbeingquestionpleasure")

                    .row.my-2
                        .col-sm
                            label.form-label() 5.	Alertness, responsiveness <br>(能够警觉，反应)* 
                        .col-sm
                            b-form-select.select(:options="wellBeingOptions" v-model="client.personalInfo.crb5c_wellbeingquestionalertness")

                    .row.my-2
                        .col-sm
                            label.form-label() 6.	Uses remaining abilities <br>(能够使用剩余能力)* 
                        .col-sm
                            b-form-select.select(:options="wellBeingOptions" v-model="client.personalInfo.crb5c_wellbeingquestionusesabilities")

                    .row.my-2
                        .col-sm
                            label.form-label() 7.	Expresses self-creativity <br>(表达创意力)* 
                        .col-sm
                            b-form-select.select(:options="wellBeingOptions" v-model="client.personalInfo.crb5c_wellbeingquestionexpresscreativity")

                    .row.my-2
                        .col-sm
                            label.form-label() 8.	Is co-operative or helpful <br>(合作)* 
                        .col-sm
                            b-form-select.select(:options="wellBeingOptions" v-model="client.personalInfo.crb5c_wellbeingquestioncooperative")

                    .row.my-2
                        .col-sm
                            label.form-label() 9.	Responds appropriately to people/situations <br>(能够适当回应人或情况)* 

                        .col-sm
                            b-form-select.select(:options="wellBeingOptions" v-model="client.personalInfo.crb5c_wellbeingquestionrespondstopeople")

                    .row.my-2
                        .col-sm
                            label.form-label() 10.	Expresses appropriate emotions <br>(能够表达适当的情绪)* 
                        .col-sm
                            b-form-select.select(:options="wellBeingOptions" v-model="client.personalInfo.crb5c_wellbeingquestionexpressesappropriate")

                    .row.my-2
                        .col-sm
                            label.form-label() 11.	Relaxed posture or body language <br>(放松的姿势或身体语言)* 

                        .col-sm
                            b-form-select.select(:options="wellBeingOptions" v-model="client.personalInfo.crb5c_wellbeingquestionrelaxedposture")

                    .row.my-2
                        .col-sm
                            label.form-label() 12.	Sense of humour <br>(有幽默感)* 
                        .col-sm
                            b-form-select.select(:options="wellBeingOptions" v-model="client.personalInfo.crb5c_wellbeingquestionsenseofhumour")

                    .row.my-2
                        .col-sm
                            label.form-label() 13.	Sense of Purpose <br>(对生活有目标)* 
                        .col-sm
                            b-form-select.select(:options="wellBeingOptions" v-model="client.personalInfo.crb5c_wellbeingquestionsenseofpurpose")

                    .row.my-2
                        .col-sm
                            label.form-label() 14.	Signs of self-respect <br>(有自尊)* 
                        .col-sm
                            b-form-select.select(:options="wellBeingOptions" v-model="client.personalInfo.crb5c_wellbeingquestionselfrespect")
          .row 
            .col-sm.my-2
              b-card.h-100.shadow(header-bg-variant="primary" header-tag="header" header-text-variant="white" v-show="validateClientWellBeing")
                template(#header)
                  h5.my-2(v-show="validateClientWellBeing") POOL ACTIVITY LEVEL (PAL) CHECKLIST
                    span.compulsory.mx-2 *
                  p(style="text-align:start;" v-show="validateClientWellBeing")  Thinking of the last two weeks, please select the statement that represents your loved one’s ability in each of the respective activities. A statement must be circled for each of the activities and there should only be one statement selected for each activity. If in doubt about which statement to choose, please choose the level of ability that represents their average performance over the last two weeks.
                .palChecklist(v-show="validateClientWellBeing")
                    .row.my-2
                        .col-sm.my-2
                            label.form-label() 1.Bathing / Washing* <br/>洗澡*
                            b-form-select.select(:options="onePalOption" v-model="client.personalInfo.crb5c_palbathingwashing" )
                        .col-sm.my-2
                            label.form-label() 2.Getting dressed* <br/>穿衣服
                            b-form-select.select(:options="twoPalOption" v-model="client.personalInfo.crb5c_palgettingdressed")
                    .row.my-2
                        .col-sm.my-2
                            label.form-label() 3.Eating*  <br/>用餐
                            b-form-select.select(:options="threePalOption" v-model="client.personalInfo.crb5c_paleating")
                        .col-sm.my-2
                            label.form-label() 4.Contact with others*  <br/>和他人交流
                            b-form-select.select(:options="fourPalOption" v-model="client.personalInfo.crb5c_palcontactwithothers")
                    .row.my-2
                        .col-sm.my-2
                            label.form-label() 5.Groupwork skills*  <br/>团队合作技巧
                            b-form-select.select(:options="fivePalOption" v-model="client.personalInfo.crb5c_palgroupworkskills")
                        .col-sm.my-2
                            label.form-label() 6.Communication skills* <br/>沟通技巧
                            b-form-select.select(:options="sixPalOption" v-model="client.personalInfo.crb5c_palcommunicationskills")
                    .row.my-2
                        .col-sm.my-2
                            label.form-label() 7.Practical activities (craft, domestic chores, gardening)*  <br/>实践活动（手工、家务、园艺）
                            b-form-select.select(:options="sevenPalOption" v-model="client.personalInfo.crb5c_palpracticalactivitiescraftetc")
                        .col-sm.my-2
                            label.form-label() 8.Use of objects*  <br/>使用工具或物品
                            b-form-select.select(:options="eightPalOption" v-model="client.personalInfo.crb5c_paluseofobjects")
                    .row.my-2
                        .col-sm.my-2
                            label.form-label() 9.Looking at a newspaper / magazine*  <br/>看报纸/杂志
                            b-form-select.select(:options="tenPalOption" v-model="client.personalInfo.crb5c_pallookingatanewspapermagazine")

          .row 
            .col-sm.my-2
              b-card.h-100.shadow(header-bg-variant="primary" header-tag="header" header-text-variant="white" v-show="validatePAL")
                template(#header)
                  h5.my-2(v-show="validatePAL") GETTING TO KNOW ME (THE CLIENT)
                  p(style="text-align:start;" v-show="validatePAL")  The information that you provide here will help our staff to support you.  It will help us in:
                      ol(style="text-align:start;") 
                          li Getting to know you
                          li Understanding who and what is important to you
                          li Knowing how you like things to be.
                  p(style="text-align:start;" v-show="validatePAL")  We invite you and your loved ones to complete this information with as much details as you wish to share with us.

                .otherInfo(v-show="validatePAL")
                    .row.my-2  
                        .col-sm
                            label.form-label() I prefer to be addressed as (for example: Peter, Nancy etc.) :
                            input#exampleFormControlInput1.form-control(type="text"  v-model="client.getToKnow.nickName" maxlength="200")
                    .row.my-2
                        .col-sm
                            label.form-label() Things that are important to me (you may select one or multiple, or type and press enter):
                            v-select(label="Hobbies" taggable=""  multiple="" :options="importantThingsOptions" v-model="client.getToKnow.importantThings")
                    
                        //to be fixed
                    //- .row.mt-3
                    //-     .col-sm
                    //-         label.form-label() If Others
                    //-         input#exampleFormControlInput1.form-control(type="text" v-model="client.getToKnow.importantThings")

                    .row.my-2
                        .col-sm
                            label.form-label() Things that help me to relax (you may select one or multiple, or type and press enter):
                    .row.my-2
                        .col-sm
                        v-select(taggable="" multiple :options="relaxOptions" v-model="client.getToKnow.relaxThings")
                    //to be fixed
                    //- .row.mt-3()
                    //-     .col-sm
                    //-         label.form-label() If Others
                    //-         input#exampleFormControlInput1.form-control(type="text" v-model="client.getToKnow.relaxThings")
                    hr
                    .row.my-2
                        .col-sm
                          label.form-label() Things that Upset Me
                          textarea#exampleFormControlInput1.form-control(type="text" v-model="client.getToKnow.thingsUpset" maxlength="200")
                    .row.my-2
                        .col-sm
                          label.form-label() My life so far  (this include your previous employment, interests, hobies, important dates & events) 
                          textarea#exampleFormControlInput1.form-control(type="text" v-model="client.getToKnow.mylifesofar" maxlength="200")
                    .row.my-2
                        .col-sm
                          label.form-label() My spiritual & cultural needs  (this can be important religious or other beliefs; or anything that makes you happy & contented) 
                          textarea#exampleFormControlInput1.form-control(type="text" v-model="client.getToKnow.spiritual" maxlength="200")
                    .row.my-2
                        .col-sm
                          label.form-label() Food & drink (Tell us about your likes & dislikes, or if you have any special diet) 
                          textarea#exampleFormControlInput1.form-control(type="text" v-model="client.getToKnow.foodAndDrink" maxlength="200")
        div.d-flex.justify-content-around.my-3
          div.m-1(v-if="isShowSavePersonalInfoBtn" )
            b-button.bg-success(v-if="isShowSavePersonalInfoBtn"  @click="openModal(0)")
              | Save Client Draft
            <!-- b-button.bg-success(v-else)
              | Save Client Draft -->
          b-button.m-1(variant="primary" v-show="validatePAL" @click="navigateToForm(formType);$scrollToTop") Proceed to Caregiver Form
        //- b-link.btn.mt-3.mb-5#fixedButton 
        //-     b-icon(icon="save" font-scale="1")
        //-     | &nbsp;Save Draft
</template>
<script>
import axios from "axios";
import HeaderComponent from "../component/HeaderComponent.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
// import {Amplify, API } from 'aws-amplify';
// import awsconfig from '@/aws-exports';
import Datepicker from 'vuejs-datepicker';
import _ from 'lodash';
import ModalConfirmation from "../component/ModalConfirmation.vue";

// Amplify.configure(awsconfig);
// API.configure(awsconfig);

export default {
  name: "ClientForm",
  components: {
    HeaderComponent,
    vSelect,
    Datepicker,
    ModalConfirmation,
  },
  async mounted() {
    if(!this.$route.query.formType){
      this.formType = "FOW"
    }else{
      this.formType = this.$route.query.formType
    }
    console.log(this.formType)
    const cd = localStorage.getItem("clientData");
    if (cd) {
      const moddedCd = JSON.parse(cd);
      delete moddedCd.personalInfo.crb5c_propertyroom;
      if (moddedCd.personalInfo.crb5c_photo) {
        this.fullProfilePic = `data:image/jpg;base64,${moddedCd.personalInfo.crb5c_photo}`;
        this.setProfilePic(this.fullProfilePic);
      }

      moddedCd.personalInfo.profilePic = null;
      this.$set(this, "client", moddedCd);
      this.$store.commit("clientDataChange", moddedCd);
    }

  },
  watch: {
    isClientInDB(val){
      if(val){
        // if(this.validateClientRegistrationIfHasBeenFilled()){
        //   this.openModal(1);
        // }

        this.openModal(1);

        return;
      }
      if(!val){
        return;
      }
    },
    client: {
      handler(val) {
        if(val.personalInfo.crb5c_nricno.length === 0 && this.isClearPersonalInfo){
          this.clearThePersonalInfoForm();
          this.isUserDataShow = false;
          this.isClearPersonalInfo = false;
        }
        
        if(this.checkValidNricCara(val.personalInfo.crb5c_nricno)[0] && !this.isUserDataShow){

          const TIMEOUT = 1500;
          (_.debounce(()=>{
            this.getRequestBasedOnNRIC(val.personalInfo.crb5c_nricno);
          }, TIMEOUT))();
          this.isUserDataShow = true;
          this.isClearPersonalInfo = true;
        }

        if (this.conditionShowSavePersonalInfoBtn(val) && !this.isPostReq) {
          this.isShowSavePersonalInfoBtn = true;
        }else{
          this.isShowSavePersonalInfoBtn = false;
        }

        if (val.personalInfo.crb5c_photo) {
          this.setProfilePic(
            this.fullProfilePic
              ? this.fullProfilePic
              : `data:image/jpg;base64,` + val.personalInfo.crb5c_photo
          );
        }

        if (val.personalInfo.nationality !== "Others") {
          this.client.personalInfo.crb5c_nationality =
            val.personalInfo.nationality;
        } else {
          this.client.personalInfo.crb5c_nationality =
            val.personalInfo.otherNationality;
        }

        if (val.personalInfo.dob) {
          this.client.personalInfo.crb5c_dob = new Date(
            val.personalInfo.dob
          ).toISOString();
          this.client.personalInfo.crb5c_birthdate = new Date(
            val.personalInfo.dob
          ).toISOString();
        }

        this.client.personalInfo.crb5c_dialect =
          val.personalInfo.dialect.join(", ");

        if (val.personalInfo.race !== "Others") {
          this.client.personalInfo.crb5c_race = val.personalInfo.race;
        } else {
          this.client.personalInfo.crb5c_race = val.personalInfo.raceOthers;
        }

        if (val.personalInfo.religion !== "Other") {
          this.client.personalInfo.crb5c_religion = val.personalInfo.religion;
        } else {
          this.client.personalInfo.crb5c_religion =
            val.personalInfo.religionOthers;
        }

        if (val.personalInfo.address) {
          this.client.personalInfo.crb5c_address = val.personalInfo.address;
        }

        if (val.personalInfo.addressLineTwo) {
          this.client.personalInfo.crb5c_address2 =
            val.personalInfo.addressLineTwo;
        }

        this.client.personalInfo.crb5c_residingwith =
          val.personalInfo.residingWith.join(", ");

        if (val.language.spokenLang?.length) {
          this.client.personalInfo.crb5c_spokenlanguages =
            val.language.spokenLang.join(", ");
        }

        if (val.language.writtenLang?.length) {
          this.client.personalInfo.crb5c_writtenlanguages =
            val.language.writtenLang.join(", ");
        }

        this.client.personalInfo.crb5c_spokendialects =
          val.language.spokenDialect.join(", ");

        if (val.education.educationHighest) {
          this.client.personalInfo.crb5c_highqualification =
            val.education.educationHighest;
        }

        if (val.education.previousOccupation) {
          this.client.personalInfo.crb5c_previousoccupation =
            val.education.previousOccupation;
        }

        if (val.education.hobbies?.length) {
          this.client.personalInfo.crb5c_hobbies =
            val.education.hobbies.join(", ");
        }

        if (val.getToKnow.nickName) {
          this.client.personalInfo.crb5c_nickname = val.getToKnow.nickName;
        }

        if (val.getToKnow.importantThings) {
          this.client.personalInfo.crb5c_thingsthatareimportant =
            val.getToKnow.importantThings.join(", ");
        }

        if (val.getToKnow.relaxThings.length) {
          this.client.personalInfo.crb5c_thingstorelax =
            val.getToKnow.relaxThings.join(", ");
        }

        if (val.getToKnow.thingsUpset) {
          this.client.personalInfo.crb5c_thingsthatupset =
            val.getToKnow.thingsUpset;
        }

        if (val.getToKnow.spiritual) {
          this.client.personalInfo.crb5c_spiritualandculturalneeds =
            val.getToKnow.spiritual;
        }

        if (val.getToKnow.foodAndDrink) {
          this.client.personalInfo.crb5c_foodanddrink =
            val.getToKnow.foodAndDrink;
        }

        this.$store.commit("clientDataChange", val);
      },
      deep: true,
    },
    profilePicBase64(val) {
      if (val) {

        const profileImg = this.$refs["profileImg"];
        this.$nextTick(() => {
          profileImg.src = val;
        });

        // console.log('base 64: ');
        // console.log(val);

        // this.setProfilePic(
        //     this.fullProfilePic
        //       ? this.fullProfilePic
        //       : `data:image/jpg;base64,` + val.personalInfo.crb5c_photo
        //   );

        // this.client.personalInfo.crb5c_photo = val;
        this.client.personalInfo.crb5c_photo = val.split(",")[1];
      }
    },
    "client.personalInfo.crb5c_postcode"(val) {
      if (val.length === 6 && !isNaN(val)) {
        this.lookupAddress(val);
      }
    },
  },
  methods: {
    navigateToForm(formType) {
      this.$router.push({
        path: '/caregiverForm',
        query: { formType: formType },
      });
    },
    modalCancelActivity({id}){
      if(id === this.modalListId[0]){
        this.dismissModal(0);
        return;
      }
      if(id === this.modalListId[1]){
        this.cancelModalPopulate();
        return;
      }
    },
    modalConfirmActivity({id}){
      if(id === this.modalListId[0]){
        this.savePersonalInfoToDB();
        return;
      }
      if(id === this.modalListId[1]){
        this.populatePersonalInfoData();
        return;
      }
    },
    conditionShowSavePersonalInfoBtn(val){
      return val.personalInfo.crb5c_no !== "" &&
          // val.personalInfo.crb5c_chinesename !== "" &&
          val.personalInfo.crb5c_idtype !== "" &&
          val.personalInfo.crb5c_nricno !== "" &&
          // val.personalInfo.profilePic &&
          // val.personalInfo.crb5c_photo !== "" &&
          val.personalInfo.crb5c_dob !== "" &&
          val.personalInfo.dob !== "" &&
          val.personalInfo.crb5c_birthdate !== "" &&
          val.personalInfo.crb5c_sex !== "" &&
          val.personalInfo.crb5c_citizenship !== "" &&
          val.personalInfo.crb5c_nationality !== "" &&
          val.personalInfo.nationality !== "" &&
          val.personalInfo.crb5c_maritalstatus !== "" &&
          val.personalInfo.crb5c_dialect !== "" &&
          val.personalInfo.dialect.length !== 0 &&
          // val.personalInfo.race !== "" &&
          // val.personalInfo.crb5c_race !== "" &&
          // val.personalInfo.religion !== "" &&
          // val.personalInfo.crb5c_religion !== "" &&
          val.personalInfo.crb5c_postcode !== "" &&
          val.personalInfo.address !== "" &&
          val.personalInfo.crb5c_address !== "" &&
          // val.personalInfo.addressLineTwo !== "" &&
          // val.personalInfo.crb5c_address2 !== "" &&
          val.personalInfo.crb5c_residingwith !== "" &&
          val.personalInfo.residingWith.length !== 0 &&
          val.personalInfo.crb5c_mycontactnumberhome !== "" &&
          val.personalInfo.crb5c_mycontactnumbermobile !== "" 
    },
    populatePersonalInfoData(){
      if(!this.tempClientPersonalData){
        return;
      }

      this.setDataPersonalInfoAfterGetRequest(this.tempClientPersonalData);
      this.isUserDataShow = true;
      this.dismissModal(1);
    },
    async getRequestBasedOnNRIC(inputNric) {
      try {
        let request = await axios.post("/api/checkuser", {
          client_nric: inputNric.toLowerCase(),
        });

        const userData = request.data.result.value[0];

        if(!userData){
          this.isClientInDB = false;
          return;
        }

        this.isClientInDB = true;
        this.isUserDataShow = true;
        this.tempClientPersonalData = userData;
        // console.log('Client Data from DB: ');
        // console.log(userData);

      } catch (error) {
        console.error(error);
        const statusCode = error?.response?.status ?? '';
        this.constructAlertMessage(`${statusCode} Something went wrong...`, this.alert.colorOption[0]);

      }
    },
    setDataPersonalInfoAfterGetRequest(userData){

      const { crb5c_fow_customerid, ...clientData } = userData;

      this.clientDBId = crb5c_fow_customerid;
      this.client.clientId = crb5c_fow_customerid;

      Object.keys(clientData).forEach((key)=>{
        if(this.validateUserData(userData, key)){
          return;
        }

        this.client.personalInfo[key] = clientData[key];
      });


      // Personal Information
      this.client.personalInfo["dob"] = this.stringConditionClientData(userData["crb5c_dob"]);
      this.client.personalInfo["nationality"] = this.stringConditionClientData(userData["crb5c_nationality"]);
      this.client.personalInfo["dialect"] = this.arrayConditionClientData(userData["crb5c_dialect"]);
      this.client.personalInfo["race"] = this.stringConditionClientData(userData["crb5c_race"]);
      this.client.personalInfo["religion"] = this.stringConditionClientData(userData["crb5c_religion"]);
      this.client.personalInfo["residingWith"] = this.arrayConditionClientData(userData["crb5c_residingwith"]);
            
      // Language Proficiency
      this.client.language.spokenLang = this.arrayConditionClientData(userData['crb5c_spokenlanguages']);
      this.client.language.writtenLang = this.arrayConditionClientData(userData['crb5c_writtenlanguages']);
      this.client.language.spokenDialect = this.arrayConditionClientData(userData['crb5c_spokendialects']);

      // Academic / Skills Attained
      this.client.education.educationHighest = this.stringConditionClientData(userData['crb5c_highqualification']);
      this.client.education.previousOccupation = this.stringConditionClientData(userData['crb5c_previousoccupation']);
      this.client.education.hobbies = this.arrayConditionClientData(userData['crb5c_hobbies']);

      // Getting to know me (THE CLIENT)
      this.client.getToKnow.nickName = this.stringConditionClientData(userData['crb5c_nickname']);
      this.client.getToKnow.importantThings = this.arrayConditionClientData(userData['crb5c_thingsthatareimportant']);
      this.client.getToKnow.relaxThings = this.arrayConditionClientData(userData['crb5c_thingstorelax']);
      this.client.getToKnow.thingsUpset = this.stringConditionClientData(userData['crb5c_thingsthatupset']);
      this.client.getToKnow.mylifesofar = this.stringConditionClientData(userData['crb5c_mylifesofar']);
      this.client.getToKnow.spiritual = this.stringConditionClientData(userData['crb5c_spiritualandculturalneeds']);
      this.client.getToKnow.foodAndDrink = this.stringConditionClientData(userData['crb5c_foodanddrink']);

    },
    stringConditionClientData(userDataValue){
      return userDataValue ?? "";
    },
    arrayConditionClientData(userDataValue){
      return userDataValue ? userDataValue.split(", ") : [];
    },
    validateClientRegistrationIfHasBeenFilled(){
      const mapClientPersonalInfo = new Map(Object.entries(this.client.personalInfo));
      
      mapClientPersonalInfo.delete('crb5c_nricno');
      mapClientPersonalInfo.delete('profilePic');
      mapClientPersonalInfo.delete('dialect');
      mapClientPersonalInfo.delete('residingWith');

      const filteredClientPersonalInfo = Object.fromEntries(mapClientPersonalInfo);

      return (
          Object.keys(filteredClientPersonalInfo).every((key)=>{
          return this.client.personalInfo[key] !== "";
        })
      );
    },
    clearThePersonalInfoForm(){

      this.tempClientPersonalData = null;
      this.isClientInDB = false;

      this.clientDBId = "";
      this.client.clientId = "";

      this.isPostReq = false;

      
      Object.keys(this.client.personalInfo).forEach((key)=>{
        if(key === 'profilePic'){
          this.client.personalInfo[key] = null;
          return;
        }
        if(key === 'dialect'){
          this.client.personalInfo[key] = [];
          return;
        }
        if(key === 'residingWith'){
          this.client.personalInfo[key] = [];
          return;
        }
        this.client.personalInfo[key] = "";
      });

      Object.keys(this.client.language).forEach((key)=>{
        if(key === 'spokenLang'){
          this.client.language[key] = [];
          return;
        }
        if(key === 'writtenLang'){
          this.client.language[key] = [];
          return;
        }
        if(key === 'spokenDialect'){
          this.client.language[key] = [];
          return;
        }
        this.client.language[key] = "";
      });

      Object.keys(this.client.education).forEach((key)=>{
        this.client.education.hobbies
        if(key === 'hobbies'){
          this.client.education[key] = [];
          return;
        }
        this.client.education[key] = "";
      });

      this.client.otherUserfulInfo = "";

      Object.keys(this.client.clientFunctionStatus).forEach((key)=>{
        this.client.clientFunctionStatus[key] = "";
      });

      Object.keys(this.client.clientWellBeingProf).forEach((key)=>{
        this.client.clientWellBeingProf[key] = "";
      });

      Object.keys(this.client.getToKnow).forEach((key)=>{
        if(key === 'importantThings'){
          this.client.getToKnow[key] = [];
          return;
        }
        if(key === 'relaxThings'){
          this.client.getToKnow[key] = [];
          return;
        }
        this.client.getToKnow[key] = "";
      });

    },
    validateUserData(userData, key){
      return userData[key] === null || userData[key] === undefined
    },
    openModal(modalIndex = 0) {
      this.$bvModal.show(this.modalListId[modalIndex]);
    },
    dismissModal(modalIndex = 0) {
      this.$bvModal.hide(this.modalListId[modalIndex]);
    },
    cancelModalPopulate(){
      this.isClientInDB = false;
      this.tempClientPersonalData = null;
      this.isUserDataShow = true;
      this.dismissModal(1);
    },
    async savePersonalInfoToDB() {
      try {
        const dataToUpdate = {
          ...this.$store.state.clientData.personalInfo,
          clientId: this.clientDBId
        };

        if(this.validateCrb5cPhoto(dataToUpdate)){
          delete dataToUpdate.crb5c_photo;
        }
        
        this.isShowSpinner = true;
        
        if(this.isClientInDB){
          // console.log('Data to Update: ');
          // console.log(dataToUpdate);
          await axios.patch('/api/updateclientmidregistration', dataToUpdate);
        }else{
          // eslint-disable-next-line no-unused-vars
          const {clientId, ...clientData} = dataToUpdate;
          
          // console.log('Data to Create: ');
          // console.log(clientData);

          await axios.post('/api/createclientmidregistration', {
            clientData,
          });

          this.isPostReq = true;
          this.isShowSavePersonalInfoBtn = false;
        }

        this.isShowSpinner = false;

        this.modalAndAlertActivity('Save client draft successful!');


      } catch (error) {
        this.isShowSpinner = false;
        console.error(error);
        this.modalAndAlertActivity(`${error.response.status} Something went wrong...`, this.alert.colorOption[0]);
      }
    },
    validateCrb5cPhoto(dataToUpdate){
      return !dataToUpdate.crb5c_photo || dataToUpdate.crb5c_photo === ""
    },
    checkValidNricCara(input) {
      let finalInput = input;
      let nricTest = /^[STFGstfg]\d{7}[A-Za-z]$/g.test(input);
      const caraTest = /^[A-Z]\d{3}[A-Z]\d{5}$/g.test(input);
      if (!nricTest && input?.length > 10) {
        finalInput = input?.slice(0, 9); //For too much info NRICs
        nricTest = /^[STFGstfg]\d{7}[A-Za-z]$/g.test(finalInput);
      }
      return [(nricTest || caraTest), finalInput];
    },
    callAlert() {
      const SECOND = 2;
      this.alert.dismissCountDown = SECOND;
    },
    modalAndAlertActivity(modalMessage, color = this.alert.colorOption[1]){
      this.constructAlertMessage(modalMessage, color);
      this.dismissModal(0);
    },
    constructAlertMessage(alertMessage, color = this.alert.colorOption[1]){
      this.alert.color = color;
      this.alert.alertMessage = alertMessage;
      this.callAlert();
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    async lookupAddress(crb5c_postcode) {
      let { data } = await axios.get(
        `https://developers.onemap.sg/commonapi/search?searchVal=${crb5c_postcode}&returnGeom=N&getAddrDetails=Y&pageNum=1`
      );

      //   const result = data?.results?.[0]?.ROAD_NAME;

      const result =
        `${data?.results?.[0].BLK_NO} ${data?.results?.[0].ROAD_NAME}, ${data?.results?.[0].BUILDING} `
          ?.replace(", NIL ", "")
          ?.trim();

      if (result) {
        this.client.personalInfo.address = result;
      }
    },
    getBase64(file) {
      var reader = new FileReader();
      
      if(!file){
        return;
      }

      if(!this.validateFileProfilePicture(file.name)){
        return;
      }

      reader.readAsDataURL(file);
      reader.onload = () => {
        this.profilePicBase64 = reader.result;
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };

    },
    validateFileProfilePicture(fileName){
        const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
        if(!allowedExtensions.exec(fileName)){
            this.constructAlertMessage(`Please upload file having extensions .jpeg, .jpg, .png only.`, this.alert.colorOption[0]);
            return false;
        }

        console.log('File is accepted!');
        return true;
    },
    profilePicSelected() {
      const profileImgFile = this.$refs["profileImgFile"];

      this.$nextTick(() => {
        this.getBase64(profileImgFile.files[0]);
      });
    },
    setProfilePic(src) {
      const profileImg = this.$refs["profileImg"];
      this.$nextTick(() => {
        profileImg.setAttribute("src", src);
      });
      
    },
    validPersonal() {
      if (
        this.client.personalInfo.crb5c_no !== "" &&
        this.client.personalInfo.crb5c_idtype !== "" &&
        this.client.personalInfo.crb5c_nricno !== "" &&
        this.client.personalInfo.crb5c_citizenship !== "" &&
        this.client.personalInfo.crb5c_maritalstatus !== "" &&
        this.client.personalInfo.dob !== "" &&
        this.client.personalInfo.crb5c_sex !== "" &&
        this.client.personalInfo.crb5c_postcode !== "" &&
        this.client.personalInfo.address !== "" &&
        (this.client.personalInfo.crb5c_mycontactnumberhome ||
          this.client.personalInfo.crb5c_mycontactnumbermobile) &&
        this.client.personalInfo.crb5c_residingwith
      ) {
        return true;
      } else {
        false;
      }
    },
    validHome() {
      if (
        this.client.personalInfo.crb5c_typeofhome !== "" &&
        (this.client.personalInfo.crb5c_propertytype !== 13 ||
          (this.client.personalInfo.crb5c_propertytype === 13 &&
            this.client.personalInfo.crb5c_propertytypeothers !== ""))
      ) {
        return true;
      } else {
        false;
      }
    },
    validLanguage() {
      if (
        this.client.language.spokenLang.length !== 0 &&
        this.client.language.writtenLang.length !== 0
      ) {
        return true;
      } else {
        false;
      }
    },
    validEdu() {
      if (
        this.client.education.educationHighest !== "" &&
        this.client.education.hobbies !== ""
      ) {
        return true;
      } else {
        false;
      }
    },

    validClientFun() {
      if (
        this.client.personalInfo.crb5c_functionalquestiontasksbilling !== "" &&
        this.client.personalInfo.crb5c_functionalquestionproperattire !== "" &&
        this.client.personalInfo.crb5c_functionalquestionwearingclothes !==
          "" &&
        this.client.personalInfo.crb5c_functionalquestionbathing !== "" &&
        this.client.personalInfo.crb5c_functionalquestiontoileting !== "" &&
        this.client.personalInfo.crb5c_functionalquestionurinary !== "" &&
        this.client.personalInfo.crb5c_functionalquestionfecalincontinence !==
          ""
      ) {
        return true;
      } else {
        false;
      }
    },
    validClientWellBeing() {
      if (
        this.client.personalInfo.crb5c_wellbeingquestioncommunicate !== "" &&
        this.client.personalInfo.crb5c_wellbeingquestionmakecontact !== "" &&
        this.client.personalInfo.crb5c_wellbeingquestionwarmth !== "" &&
        this.client.personalInfo.crb5c_wellbeingquestionpleasure !== "" &&
        this.client.personalInfo.crb5c_wellbeingquestionalertness !== "" &&
        this.client.personalInfo.crb5c_wellbeingquestionusesabilities !== "" &&
        this.client.personalInfo.crb5c_wellbeingquestionexpresscreativity !==
          "" &&
        this.client.personalInfo.crb5c_wellbeingquestioncooperative !== "" &&
        this.client.personalInfo.crb5c_wellbeingquestionrespondstopeople !==
          "" &&
        this.client.personalInfo.crb5c_wellbeingquestionexpressesappropriate !==
          "" &&
        this.client.personalInfo.crb5c_wellbeingquestionrelaxedposture !== "" &&
        this.client.personalInfo.crb5c_wellbeingquestionsenseofhumour !== "" &&
        this.client.personalInfo.crb5c_wellbeingquestionsenseofpurpose !== "" &&
        this.client.personalInfo.crb5c_wellbeingquestionselfrespect !== ""
      ) {
        return true;
      } else {
        false;
      }
    },
    validPAL() {
      if (
        this.client.personalInfo.crb5c_palbathingwashing !== "" &&
        this.client.personalInfo.crb5c_palgettingdressed !== "" &&
        this.client.personalInfo.crb5c_paleating !== "" &&
        this.client.personalInfo.crb5c_palcontactwithothers !== "" &&
        this.client.personalInfo.crb5c_palgroupworkskills !== "" &&
        this.client.personalInfo.crb5c_palcommunicationskills !== "" &&
        this.client.personalInfo.crb5c_palpracticalactivitiescraftetc !== "" &&
        this.client.personalInfo.crb5c_paluseofobjects !== "" &&
        this.client.personalInfo.crb5c_pallookingatanewspapermagazine !== ""
      ) {
        return true;
      } else {
        false;
      }
    },
    validGTK() {
      return true;
      // if (
      //   this.client.getToKnow.nickName !== "" &&
      //   this.client.getToKnow.importantThings.length !== 0 &&
      //   this.client.getToKnow.spiritual !== "" &&
      //   this.client.getToKnow.foodAndDrink !== ""
      // ) {
      //   return true;
      // } else {
      //   false;
      // }
    },
  },
  computed: {
    extraPersonalInfoValid() {
      return (
        !this.invalidName &&
        ((!this.homeContactInvalid &&
          this.client.personalInfo.crb5c_mycontactnumberhome) ||
          (!this.mobileContactInvalid &&
            this.client.personalInfo.crb5c_mycontactnumbermobile))
      );
    },
    invalidName() {
      return !/^[a-z ,.'-]+$/i.test(this.client.personalInfo.crb5c_no);
    },
    homeContactInvalid() {
      return (
        this.client.personalInfo.crb5c_mycontactnumberhome &&
        !/\b^\d{8}\b/g.test(
          +this.client.personalInfo.crb5c_mycontactnumberhome
        )
      );
    },
    mobileContactInvalid() {
      return (
        this.client.personalInfo.crb5c_mycontactnumbermobile &&
        !/\b^\d{8}\b/g.test(
          +this.client.personalInfo.crb5c_mycontactnumbermobile
        )
      );
    },
    clientFuncRisk: {
      get() {
        if (
          this.client.personalInfo.crb5c_functionalquestionfecalincontinence ===
          "Yes"
        ) {
          return "6e Moderately Severe";
        } else if (
          this.client.personalInfo.crb5c_functionalquestionurinary === "Yes"
        ) {
          return "6d Moderately Severe";
        } else if (
          this.client.personalInfo.crb5c_functionalquestiontoileting === "Yes"
        ) {
          return "6c Moderately Severe";
        } else if (
          this.client.personalInfo.crb5c_functionalquestionbathing === "Yes"
        ) {
          return "6b Moderately Severe";
        } else if (
          this.client.personalInfo.crb5c_functionalquestionwearingclothes ===
          "Yes"
        ) {
          return "6a Moderately Severe";
        } else if (
          this.client.personalInfo.crb5c_functionalquestionproperattire ===
          "Yes"
        ) {
          return "5 Moderate";
        } else if (
          this.client.personalInfo.crb5c_functionalquestiontasksbilling ===
          "Yes"
        ) {
          return "4 Mild";
        } else {
          return " ";
        }
      },

      set() {},
    },
    validatePersonal() {
      if (this.validPersonal() && this.extraPersonalInfoValid) {
        return true;
      } else {
        return false;
      }
    },
    validateTypesOfHome() {
      if (this.validHome()) {
        return true;
      } else {
        return false;
      }
    },
    validateLangProf() {
      if (this.validLanguage()) {
        return true;
      } else {
        return false;
      }
    },
    validateAcademic() {
      if (this.validEdu()) {
        return true;
      } else {
        return false;
      }
    },
    validateClientFunc() {
      if (this.validClientFun()) {
        return true;
      } else {
        return false;
      }
    },
    validateClientWellBeing() {
      if (this.validClientWellBeing()) {
        return true;
      } else {
        return false;
      }
    },
    validatePAL() {
      if (this.validPAL()) {
        return true;
      } else {
        return false;
      }
    },
    validateGetToKnow() {
      if (this.validGTK()) {
        return true;
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      formType:'',
      role: true,
      isShowSavePersonalInfoBtn: false,
      isUserDataShow: false,
      isClearPersonalInfo: false,
      isShowSpinner: false,
      clientDBId: "",
      modalListId: ["modal-personal-info-confirmation", "modal-nric-get-confirmation"],
      isClientInDB: false,
      isPostReq: false,
      tempClientPersonalData: null,
      alert: {
        color: "",
        colorOption: ["danger", "success"],
        dismissSecs: 5,
        dismissCountDown: 0,
        showDismissibleAlert: true,
        alertMessage: "",
      },
      profilePicBase64: "",
      religionList: [
        "Buddhism",
        "Catholic",
        "Christian",
        "Hindu",
        "Islam",
        "Nil",
        "Other",
      ],
      countryList: [
        "Afghanistan",
        "Åland Islands",
        "Albania",
        "Algeria",
        "American Samoa",
        "Andorra",
        "Angola",
        "Anguilla",
        "Antarctica",
        "Antigua and Barbuda",
        "Argentina",
        "Armenia",
        "Aruba",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bolivia",
        "Bonaire, Sint Eustatius and Saba",
        "Bosnia and Herzegovina",
        "Botswana",
        "Bouvet Island",
        "Brazil",
        "British Indian Ocean Territory",
        "Brunei Darussalam",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cabo Verde",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Cayman Islands",
        "Central African Republic",
        "Chad",
        "Chile",
        "China",
        "Christmas Island",
        "Cocos Keeling Islands",
        "Colombia",
        "Comoros",
        "The Democratic Republic of the Congo",
        "The Congo",
        "Cook Islands",
        "Costa Rica",
        "Croatia",
        "Cuba",
        "Curaçao",
        "Cyprus",
        "Czechia",
        "Côte d'Ivoire",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Eswatini",
        "Ethiopia",
        "Falkland Islands",
        "Faroe Islands",
        "Fiji",
        "Finland",
        "France",
        "French Guiana",
        "French Polynesia",
        "French Southern Territories",
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Gibraltar",
        "Greece",
        "Greenland",
        "Grenada",
        "Guadeloupe",
        "Guam",
        "Guatemala",
        "Guernsey",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Heard Island and McDonald Islands",
        "Holy See",
        "Honduras",
        "Hong Kong",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran",
        "Iraq",
        "Ireland",
        "Isle of Man",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jersey",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "South Korea ",
        "North Korea",
        "Kuwait",
        "Kyrgyzstan",
        "Lao People's Democratic Republic",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macao",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands",
        "Martinique",
        "Mauritania",
        "Mauritius",
        "Mayotte",
        "Mexico",
        "Micronesia",
        "Moldova",
        "Monaco",
        "Mongolia",
        "Montenegro",
        "Montserrat",
        "Morocco",
        "Mozambique",
        "Myanmar",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands",
        "New Caledonia",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "Niue",
        "Norfolk Island",
        "Northern Mariana Islands ",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Palestine, State of",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines ",
        "Pitcairn",
        "Poland",
        "Portugal",
        "Puerto Rico",
        "Qatar",
        "Republic of North Macedonia",
        "Romania",
        "Russian Federation",
        "Rwanda",
        "Réunion",
        "Saint Barthélemy",
        "Saint Helena, Ascension and Tristan da Cunha",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Martin (French part)",
        "Saint Pierre and Miquelon",
        "Saint Vincent and the Grenadines",
        "Samoa",
        "San Marino",
        "Sao Tome and Principe",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Sint Maarten (Dutch part)",
        "Slovakia",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "South Africa",
        "South Georgia and the South Sandwich Islands",
        "South Sudan",
        "Spain",
        "Sri Lanka",
        "Sudan ",
        "Suriname",
        "Svalbard and Jan Mayen",
        "Sweden",
        "Switzerland",
        "Syrian Arab Republic",
        "Taiwan",
        "Tajikistan",
        "Tanzania, United Republic of",
        "Thailand",
        "Timor-Leste",
        "Togo",
        "Tokelau",
        "Tonga",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Turks and Caicos Islands",
        "Tuvalu",
        "Uganda",
        "Ukraine",
        "United Arab Emirates ",
        "United Kingdom of Great Britain and Northern Ireland ",
        "United States Minor Outlying Islands",
        "United States of America",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Venezuela ",
        "Viet Nam",
        "Virgin Islands (British)",
        "Virgin Islands (U.S.)",
        "Wallis and Futuna",
        "Western Sahara",
        "Yemen",
        "Zambia",
        "Zimbabwe",
      ],

      client: {
        clientId: '',
        personalInfo: {
          crb5c_no: "",
          crb5c_chinesename: "",

          crb5c_idtype: "",
          idType: "",
          crb5c_nricno: "",

          profilePic: null,
          crb5c_photo: "",

          crb5c_citizenship: "",

          nationality: "",
          otherNationality: "",

          crb5c_nationality: "",
          crb5c_maritalstatus: "",

          dob: "",
          crb5c_dob: "",
          crb5c_birthdate: "",
          crb5c_age: "",

          crb5c_sex: "",

          dialect: [],
          crb5c_dialect: "",
          ifOtherDialect: "",

          race: "",
          raceOthers: "",
          crb5c_race: "",

          religion: "",
          religionOthers: "",
          crb5c_religion: "",

          address: "",
          crb5c_address: "",

          addressLineTwo: "",
          crb5c_address2: "",

          crb5c_postcode: "",

          residingWith: [],
          crb5c_residingwith: "",

          crb5c_mycontactnumberhome: "",
          crb5c_mycontactnumbermobile: "",

          otherResiding: "",

          crb5c_typeofhome: "",
          //crb5c_propertyroom: 1,
          crb5c_propertytype: "",
          crb5c_propertytypeothers: "",

          crb5c_spokenlanguages: "",
          crb5c_writtenlanguages: "",
          crb5c_spokendialects: "",

          crb5c_highqualification: "",
          crb5c_previousoccupation: "",
          crb5c_hobbies: "",

          crb5c_otherinfo: "",

          crb5c_functionalquestiontasksbilling: "",
          crb5c_functionalquestionproperattire: "",
          crb5c_functionalquestionwearingclothes: "",
          crb5c_functionalquestionbathing: "",
          crb5c_functionalquestiontoileting: "",
          crb5c_functionalquestionurinary: "",
          crb5c_functionalquestionfecalincontinence: "",

          crb5c_wellbeingquestioncommunicate: "",
          crb5c_wellbeingquestionmakecontact: "",
          crb5c_wellbeingquestionwarmth: "",
          crb5c_wellbeingquestionpleasure: "",
          crb5c_wellbeingquestionalertness: "",
          crb5c_wellbeingquestionusesabilities: "",
          crb5c_wellbeingquestionexpresscreativity: "",
          crb5c_wellbeingquestioncooperative: "",
          crb5c_wellbeingquestionrespondstopeople: "",
          crb5c_wellbeingquestionexpressesappropriate: "",
          crb5c_wellbeingquestionrelaxedposture: "",
          crb5c_wellbeingquestionsenseofhumour: "",
          crb5c_wellbeingquestionsenseofpurpose: "",
          crb5c_wellbeingquestionselfrespect: "",

          crb5c_palbathingwashing: "",
          crb5c_palgettingdressed: "",
          crb5c_paleating: "",
          crb5c_palcontactwithothers: "",
          crb5c_palgroupworkskills: "",
          crb5c_palcommunicationskills: "",
          crb5c_palpracticalactivitiescraftetc: "",
          crb5c_paluseofobjects: "",
          crb5c_pallookingatanewspapermagazine: "",

          crb5c_nickname: "",
          crb5c_mylifesofar: "",
          crb5c_thingsthatareimportant: "",
          crb5c_thingstorelax: "",
          crb5c_thingsthatupset: "",
          crb5c_spiritualandculturalneeds: "",
          crb5c_foodanddrink: "",
        },

        language: {
          spokenLang: [],
          ifOtherSpoken: "",
          writtenLang: [],
          ifOtherWritten: "",
          spokenDialect: [],
          ifOtherDialect: "",
        },
        education: {
          educationHighest: "",
          previousOccupation: "",
          hobbies: [],
        },
        otherUserfulInfo: "",
        clientFunctionStatus: {
          crb5c_functionalquestiontasksbilling: "",
          crb5c_functionalquestionproperattire: "",
          qThree: "",
          qFours: "",
          qFive: "",
          qSix: "",
          qSeven: "",
          ClientFuncLevel: "",
        },
        clientWellBeingProf: {
          qOne: "",
          qTwo: "",
          qThree: "",
          qFour: "",
          qFive: "",
          qSix: "",
          qSeven: "",
          qEight: "",
          qNine: "",
          qTen: "",
          qEleven: "",
          qTwelve: "",
          qThirteen: "",
          qFourteen: "",
        },

        getToKnow: {
          mylifesofar: "",
          nricName: "",
          nickName: "",
          importantThings: [],
          relaxThings: [],
          thingsUpset: "",
          spiritual: "",
          foodAndDrink: "",
        },
      },
      personalInfoKeyGetRequest: [
        "crb5c_no",
        "crb5c_chinesename",
        "crb5c_idtype",
        "crb5c_nricno",
        "crb5c_photo",
        "crb5c_dob",
        "crb5c_birthdate",
        "crb5c_sex",
        "crb5c_citizenship",
        "crb5c_nationality",
        "crb5c_maritalstatus",
        "crb5c_dialect",
        "crb5c_race",
        "crb5c_religion",
        "crb5c_postcode",
        "crb5c_address",
        "crb5c_address2",
        "crb5c_residingwith",
        "crb5c_mycontactnumberhome",
        "crb5c_mycontactnumbermobile"
      ],
      idOptions: [
        { label: "NRIC", value: 0 },
        { label: "FIN", id: 1 },
      ],
      sexOptions: ["Male", "Female"],
      importantThingsOptions: ["Family", "Friends", "Home", "Pets"],
      nationalityOption: ["Singaporean", "Malaysian", "Others"],
      citizenShipOption: [
        "Singapore Citizen",
        "Singapore P.R.",
        "Non-Singaporean",
      ],
      relaxOptions: ["Painting", "Gardening", "Mahjong"],
      maritalOption: ["Single", "Married", "Divorced", "Separated", "Widowed"],
      crb5c_dialectOption: [
        "Cantonese",
        "Hakka",
        "Hokkien",
        "Shanghainese",
        "Hainanese",
        "HengHua",
        "Fuzhou",
        "Teochew",
      ],
      raceOption: ["Chinese", "Indian", "Malay", "Eurasian", "Others"],
      crb5c_residingwithOption: [
        "Spouse",
        "Son",
        "Daughter",
        "Domestic Helper",
        "Alone",
        "Spouse and son",
        "Spouse and daughter",
        "Spouse and son's family",
        "Spouse and daughter's family",
        "Friend",
      ],
      homeOwnershipOption: [
        { text: "Rental", value: 0 },
        { text: "Owned", value: 1 },
      ],
      houseRoomOption: [
        { text: "1-Room", value: 0 },
        { text: "2-Room", value: 1 },
        { text: "3-Rooms", value: 2 },
        { text: "4-Rooms", value: 3 },
        { text: "5-Rooms", value: 4 },
      ],
      houseTypeOption: [
        { text: "HDB 1-Room", value: 1 },
        { text: "2-Rooms", value: 2 },
        { text: "3-Rooms", value: 3 },
        { text: "4-Rooms", value: 4 },
        { text: "5-Rooms", value: 5 },
        { text: "HDB Exec", value: 6 },
        { text: "Shop House", value: 7 },
        { text: "HUDC Flat", value: 8 },
        { text: "Private Flat", value: 9 },
        { text: "Terrace", value: 10 },
        { text: "Semi-D", value: 11 },
        { text: "Bungalow", value: 12 },
        { text: "Others", value: 13 },
      ],
      spokenLangOptions: ["Bahasa", "English", "Mandarin", "Tamil"],
      writtenLangOptions: ["Chinese", "English", "Malay", "Tamil"],
      spokenDialectOption: [
        "Cantonese",
        "Hakka",
        "Hokkien",
        "Shanghainese",
        "Hainanese",
        "HengHua",
        "Fuzhou",
        "Teochew",
        "Others",
      ],
      hobbiesOption: [
        "Cooking",
        "Baking",
        "Flower arrangement",
        "Gardening",
        "Golf",
        "Swimming",
        "Jogging",
        "Sewing",
        "Reading",
        "Singing",
        "Dancing",
        "Playing Mahjong",
        "Window Shopping",
      ],
      highestEduOption: [
        "Primary",
        "Secondary",
        "Pre-University",
        "Diploma",
        "Associate Degree",
        "Bachelor’s Degree",
        "Master’s Degree",
        "Doctorate Degree",
      ],
      yesNoOptions: [
        { text: "Yes 需要", value: true },
        { text: "No 不需要", value: false },
      ],
      wellBeingOptions: [
        { text: "Select One", disabled: true, value: null },
        { text: "No sign 没有迹象", value: 0 },
        { text: "Some sign一些迹象", value: 1 },
        { text: "Significant sign 明显迹象", value: 2 },
      ],
      onePalOption: [
        { text: "Select One", disabled: true, value: null },
        {
          text: "Can bathe / wash independently, sometimes with a little help to start - 可以独立洗澡/洗，有时需要一点帮助才能开始",
          value: 0,
        },
        {
          text: "Needs soap put on flannel and one-step-at-a-time directions to wash - 需要将肥皂放在毛巾上和一步一步的说明怎样洗澡",
          value: 1,
        },
        {
          text: "Mainly relies on others but will wipe own face and hands if encouraged - 主要依赖他人，但如果鼓励会擦自己的脸和手",
          value: 2,
        },
        {
          text: "Totally dependent and needs full assistance to wash or bathe - 完全依赖并需要全力协助洗澡",
          value: 3,
        },
      ],
      twoPalOption: [
        { text: "Select One", disabled: true, value: null },
        {
          text: "Plans what to wear, selects own clothing from cupboards; dresses in correct order - 计划穿什么，从橱柜里挑选自己的衣服； 穿着正确的顺序",
          value: 0,
        },
        {
          text: "Needs help to plan what to wear but recognizes items and how to wear them; needs help with order of dressing - 需要帮助来计划穿什么，但认得衣或裤。也懂得如何穿它们； 在穿衣顺序方面需要帮助",
          value: 1,
        },
        {
          text: "Needs help to plan, and with order of dressing, but can carry out small activities if someone directs each step - 需要帮助进行计划和穿衣顺序，但如果有人指导每一步，则可以穿衣",
          value: 2,
        },
        {
          text: "Totally dependent on someone to plan, sequence and complete dressing; may move limbs to assist - 完全依赖他人来计划、排序和完成穿衣； 可以移动四肢来协助穿衣",
          value: 3,
        },
      ],
      threePalOption: [
        { text: "Select One", disabled: true, value: null },
        {
          text: "Eats independently and appropriately using the correct cutlery - 独立且适当地使用正确的餐具用餐",
          value: 0,
        },
        {
          text: "Eats using a spoon and/or needs food to be cut up into small pieces - 使用汤匙用餐和/或需要将食物切成小块",
          value: 1,
        },
        { text: "Only uses fingers to eat food - 只用手吃东西", value: 2 },
        { text: "Relies on others to be fed - 靠别人喂他用餐", value: 3 },
      ],
      fourPalOption: [
        { text: "Select One", disabled: true, value: null },
        {
          text: "Initiates social contact and responds to the needs of others - 能够自动和他人交流，谈话，并能够回应他人的需求",
          value: 0,
        },
        {
          text: "Aware of others and will seek interaction, but may be more concerned with own needs - 了解他人的存在，并会寻求互动，但可能更关心自己的需求",
          value: 1,
        },
        {
          text: "Aware of others but waits for others to make the first social contact - 了解他人的存在，但等待他人主动和他交谈",
          value: 2,
        },
        {
          text: "May not show an awareness of the presence of others unless in direct physical contact - 除非有直接的身体接触，否则可能不会意识到他人的存在",
          value: 3,
        },
      ],
      fivePalOption: [
        { text: "Select One", disabled: true, value: null },
        {
          text: "Engages with others in a group activity, can take turns with the activity / tools - 在小组活动中与他人互动，可以轮流使用活动/工具",
          value: 0,
        },
        {
          text: "Occasionally engages with others in a group, moving in and out of the group at whim - 偶尔能够与小组的组员互动，随心所欲地进出小组互动",
          value: 1,
        },
        {
          text: "Aware of others in the group and will work alongside others although tends to focus on own activity - 了解他人的存在，能够和小组进行活动，但可能更关心自己的活动",
          value: 2,
        },
        {
          text: "Does not show awareness of others in the group unless close one-to-one attention is experienced - 除非有一对一的接触，否则可能不会意识到他人的存在",
          value: 3,
        },
      ],
      sixPalOption: [
        { text: "Select One", disabled: true, value: null },
        {
          text: "Is aware of appropriate interaction, can chat coherently and is able to use complex language skills - 意识到适当的互动，可以连贯地聊天并且能够使用复杂的语言技能",
          value: 0,
        },
        {
          text: "Body language may be inappropriate and may not always be coherent, but can use simple language skills - 肢体语言可能不恰当，也不一定总是如此连贯，但可以使用简单的语言技能",
          value: 1,
        },
        {
          text: "Responses to verbal interaction may be mainly through body language; comprehension is limited - 对言语互动的反应可能主要通过身体语言; 理解力有限",
          value: 2,
        },
        {
          text: "Can only respond to direct physical contact from others through touch, eye contact or facial expression - 只能通过以下方式回应他人：直接身体接触触摸、目光接触或面部表情",
          value: 3,
        },
      ],
      sevenPalOption: [
        { text: "Select One", disabled: true, value: null },
        {
          text: "Can plan to carry out an activity, hold the goal in mind and work through a familiar sequence; may need help solving problems - 可以计划开展一项活动，并按照熟悉的顺序进行工作； 可能需要帮助解决问题",
          value: 0,
        },
        {
          text: "More interested in the making or doing than in the end result, needs prompting to remember purpose, can get distracted - 对制作或做事比对最终结果更感兴趣，需要提示记住目的，可能会分心",
          value: 1,
        },
        {
          text: "Activities need to be broken down and presented one step at a time; multisensory stimulation can help to hold the attention - 活动需要分解并一次一步呈现； 多感官刺激有助于保持注意力",
          value: 2,
        },
        {
          text: "Unable to ‘do’ activities, but responds to the close contact of others and experiencing physical sensations - 无法“做”活动，但对密切接触者有反应其他人并体验身体感觉",
          value: 3,
        },
      ],
      eightPalOption: [
        { text: "Select One", disabled: true, value: null },
        {
          text: "Plans to use and looks for objects that are not visible; may struggle if objects are not in usual / familiar places (i.e. toiletries in a cupboard below washbasin) - 能够寻找不在的物品或工具； 但如果物品不在通常/熟悉的地方（例如洗脸盆下方橱柜中的洗漱用品），可能会遇到困难",
          value: 0,
        },
        {
          text: "Selects objects appropriately only if in view (i.e. toiletries on a shelf next to washbasin) - 只有在视野范围内才能适当地选择工具或物品（即洗脸盆旁边架子上的洗漱用品）",
          value: 1,
        },
        {
          text: "Randomly uses objects as chances upon them; may use inappropriately - 随便使用看到的工具或物品； 但可能使用不当",
          value: 2,
        },
        {
          text: "May grip objects when placed in the hand but will not attempt to use them - 如果把物品或工具放在手中，可能会抓住它，但不会尝试使用它们",
          value: 3,
        },
      ],
      tenPalOption: [
        { text: "Select One", disabled: true, value: null },
        {
          text: "Comprehends and shows interest in the content, turns the pages and looks at headlines and pictures - 理解内容并表现出对内容的兴趣，能够翻页并查看标题和图片",
          value: 0,
        },
        {
          text: "Turns the pages randomly, only attending to items pointed out by others - 随便翻页，只注意别人指出的项目",
          value: 1,
        },
        {
          text: "Will hold and may feel the paper, but will not turn the pages unless directed and will not show interest in the content - 会握住并可能会感觉到纸张，但除非得到指示，否则不会翻页，并且不会对内容表现出兴趣",
          value: 2,
        },
        {
          text: "May grip the paper if it is placed in the hand but may not be able to release grip; or may not take hold of the paper - 如果将纸张放在手中，可能会抓住纸张但可能无法松开； 或者可能不会抓住纸张",
          value: 3,
        },
      ],
      noneOption: [" "],
      fullProfilePic: "",
    };
  },
};
</script>

<style lang="scss" scoped>
$primary: #4b2b67;

header {
  background-color: #4b2b67 !important;  /* Use your custom color code here */
  color: #fff; /* Optionally, set the text color for better visibility */
  width: 100%; /* Make sure the header takes the full width of the card */
  box-sizing: border-box; 
  margin: 0;/* Include padding and border in the width calculation */
}
.margin-container {
  margin: 0 12%;
}

@media only screen and (max-width: 1200px) {
  .margin-container {
    margin: 0 8%;
  }
}

@media only screen and (max-width: 768px) {
  .margin-container {
    margin: 0 8%;
  }
}

@media only screen and (max-width: 480px) {
  .margin-container {
    margin: 0 4%;
  }
}
.noWhiteSpace {
  white-space: nowrap;
}
label {
  width: 100%;
  text-align: start;
}
.select {
  width: 100%;
  height: 35px;
}
#profileImg {
  width: 50%;
}
#fixedButton {
  position: fixed;
  top: 90vh;
  left: 82%;

  background-color: $primary;
  color: white;
}

.custom-container{
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.compulsory{
 color: red;
 font-weight: bold;
 font-size: 1.5rem;
}
</style>
